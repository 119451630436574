// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


function importAll(r) {
  r.keys().forEach(r);
}
// Add relevant file extensions as needed below.
// I'm sure there is a better way :shrug:
importAll(require.context('../images/', true, /\.(svg|jpg|png)$/));

// import { detectAnyAdblocker } from 'just-detect-adblock'

class Confirm {
  constructor(el) {
    this.message = el.getAttribute('data-confirm')
    if (this.message) {
      el.form.addEventListener('submit', this.confirm.bind(this))
    } else {
      console && console.warn('No value specified in `data-confirm`', el)
    }
  }

  confirm(e) {
    if (!window.confirm(this.message)) {
      e.preventDefault();
    }
  }
}

document.addEventListener("DOMContentLoaded",function(){
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  Array.from(document.querySelectorAll('[data-confirm]')).forEach((el) => {
    new Confirm(el)
  })

  // let cookieAgreement = document.getElementById('cookie-agreement')
  // let cookieBar = document.getElementById('cookie-bar')

  // function setCookie(name, value, days) {
  //   var expires;
  //   if (days) {
  //     var date = new Date();
  //     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //     expires = "; expires=" + date.toGMTString();
  //   }
  //   else {
  //     expires = "";
  //   }
  //   document.cookie = name + "=" + value + expires + "; path=/";
  // }

  // function getCookie(name) {
  //   var dc = document.cookie;
  //   var prefix = name + "=";
  //   var begin = dc.indexOf("; " + prefix);
  //   if (begin == -1) {
  //     begin = dc.indexOf(prefix);
  //     if (begin != 0) return null;
  //   }
  //   else
  //   {
  //     begin += 2;
  //     var end = document.cookie.indexOf(";", begin);
  //     if (end == -1) {
  //       end = dc.length;
  //     }
  //   }

  //   return decodeURI(dc.substring(begin + prefix.length, end));
  // }

  // const hideCookies = () => {
  //   cookieBar.style.display = "none"
  // }

  // const hideAdBlockPopup = () => {
  //   antiAdblockPopup.style.display = "none"
  // }

  let mainContent = document.getElementById('main-content')
  let footer = document.getElementById('footer')
  let backgroundOverlay = document.getElementById('transparent-bg')
  mainContent.addEventListener(`click`, hidemenu);
  backgroundOverlay.addEventListener(`click`, hidemenu);
  footer.addEventListener(`click`, hidemenu);
  
  function hidemenu(e) {
    let btnChecked = document.getElementById('menu-btn').checked

    if (btnChecked === true) {
      document.getElementById('menu-btn').checked = false
    }
  }

  // if (cookieAgreement) {
  //   cookieAgreement.addEventListener(`click`, function(){setCookie('cookie_notice_accepted', 'true', 182)});
  //   cookieAgreement.addEventListener(`click`, hideCookies);
  // }

  // detectAnyAdblocker().then((detected) => {
  //   if(detected && getCookie('anti_adblock_accepted') != true) {
  //     let adblockAgreement = document.getElementById('adblock-agreement')
  //     let antiAdblockPopup = document.getElementById('anti-adblock-popup')

  //     antiAdblockPopup.style.display = "flex"

  //     const hideAdBlockPopup = () => {
  //       antiAdblockPopup.style.display = "none"
  //     }

  //     if (adblockAgreement) {
  //       adblockAgreement.addEventListener(`click`, function(){setCookie('anti_adblock_accepted', 'true', 30)});
  //       adblockAgreement.addEventListener(`click`, hideAdBlockPopup);
  //     }
  //   } else {
  //     setCookie('anti_adblock_accepted', 'true', 21)
  //   }
  // });
});

